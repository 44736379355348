export const projects = [
    {
        key: '00001',
        name: 'wevops',
        image: 'wevops',
        backgroundColor: 'black',
        headerColor: 'white',
        textColor: '#999999',
        description: 'The website was developed for a startup that specialises in UI/UX design and web development. It was created using React js, styled components and gsap for animations. I was involved in both the design and development of this website.',
        site: 'https://wevops.co/',
    },
    {
        key: '00002',
        name: 'edanra',
        image: 'edanra',
        backgroundColor: '#FCFCFC',
        headerColor: '#0E4D40',
        textColor: 'black',
        description: 'This website was developed for Edanra, a startup that provides a platform to link house owners to those in need of housing especially National service personnel. It was designed using Adobe XD, and developed with React js, css, redux and redux sagas. Its backend was hosted on firebase.',
        site: 'https://edanra.com/',
    },
    {
        key: '00003',
        name: 'evodia',
        image: 'evodia',
        backgroundColor: '#222020',
        headerColor: '#C75A56',
        textColor: '#999999',
        description: 'This e-commerce web app was developed for a perfume selling company. It was designed with adobe xd and developed with next js, paystack, styled components, redux, and gsap. It was then hosted on vercel.',
        site: 'https://evodia.vercel.app'

    },
    // {
    //     key: '00004',
    //     name: 'fr logistics',
    //     image: 'fr',
    //     backgroundColor: 'black',
    //     headerColor: 'white',
    //     textColor: '#999999',
    //     description: 'This website was developed for FrLogistics, a logistics moving and warehousing company in Ghana. It was designed with webflow and developed with HTML, CSS and javascript. The backend was developed using Django. I was mainly involved with the frontend but helped as well with setting up part of the backend.',
    //     site: 'https://frlogisticsltd.com/',
    // },
    {
        key: '00004',
        name: 'qube pcs',
        image: 'qube',
        backgroundColor: 'white',
        headerColor: '#3C2517',
        textColor: 'black',
        description: 'This website was build for Qube Pcs, an international consulting firm involved in various sectors such as onstruction, aviation, infrastructure, water & utilities, environmental development, property markets and football management. The website was built with gatsby and gsap.',
        site: 'https://qubepcs.com/',
    },
    {
        key: '00005',
        name: 'design studios',
        image: 'design',
        backgroundColor: '#23383B',
        headerColor: 'white',
        textColor: '#E7E7E7',
        description: 'Design portfolio is being developed for a design company involved in graphic design and animations. Dummy data has been used for the meantime. It is being developed with React js and sass, with me being involved in the design of the website and it\'s development',
        site: 'https://drvmroll-design-portfolio.netlify.app/',
    },
    {
        key: '00006',
        name: 'koffee.',
        image: 'koffee',
        backgroundColor: 'white',
        headerColor: '#6F4E37',
        textColor: 'black',
        description: 'Koffee was developed with gatsby and gsap. It was developed to for a conveniently named imaginary coffee shop: "Koffee". It was hosted on netlify.',
        site: 'https://koffeeshop.netlify.app/',
    },
    {
        key: '00007',
        name: 'chop flix',
        image: 'chop',
        backgroundColor: 'black',
        headerColor: 'white',
        textColor: '#999999',
        description: 'Chop Flix is an ecommerce platform used to sell drum packs for music producers. The website is still in development. So far, the main technology that has been used is React.',
        site: 'https://chop-flix.netlify.app/',
    },
]
